import React from "react";
import tw from "twin.macro";
import { GoogleLogout } from "react-google-login";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import axios from "axios";
import { BASE_URL, SERVER_URL } from "utils/urlConfig"; // eslint-disable-line

const MySwal = withReactContent(Swal);

const clientId =
  "37763169419-3gikn42spq9o7f2tdd52vbdb84lb6n8m.apps.googleusercontent.com";


const PrimaryAction = tw.button`px-8 text-sm text-base px-8 py-4 bg-gray-100 text-primary-500 font-bold rounded shadow transition duration-300 hocus:bg-primary-500 hocus:text-gray-100 focus:shadow-outline`;

function Logout() {
  let history = useHistory();
  axios.defaults.withCredentials = true;
  const onSuccess = () => {
    axios.get(SERVER_URL + "/api/destroysession").then((response) => {
      MySwal.fire({
        icon: "success",
        title: <p>Berhasil Logout</p>,
      });
      history.push("/");
    });
  };

  return (
    <div>
      <GoogleLogout
        clientId={clientId}
        render={(renderProps) => (
          <PrimaryAction
            onClick={renderProps.onClick}
            disabled={renderProps.disabled}
          >
            Logout
          </PrimaryAction>
        )}
        onFailure={(err) => {
          // console.log("fail", err)
        }}
        buttonText="Logout"
        onLogoutSuccess={onSuccess}
      ></GoogleLogout>
    </div>
  );
}

export default Logout;
