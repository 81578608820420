import "tailwindcss/dist/base.css";
import "styles/globalStyles.css";
import React, { Suspense, lazy } from "react";
import { css } from "styled-components/macro"; //eslint-disable-line

// import AnimationRevealPage from "helpers/AnimationRevealPage.js";
// import HomePage from "module/HomePage.js";
// import ProfilPage from "module/ProfilPage.js";
// import WizardPage from "module/WizardPage.js";
// import FormulirPdf from "module/profil/FormulirPdf2.js";
// import LandingPage from "module/main/LandingPage.js";
// import Persyaratan from "module/main/Persyaratan.js";

/* Inner Pages */
import LoginPage from "pages/Login.js";
import ComponentRenderer from "ComponentRenderer.js";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
// import DaftarUlang from "module/profil/DaftarUlang";

const AnimationRevealPage = lazy(() =>
  import("helpers/AnimationRevealPage.js")
);
const HomePage = lazy(() => import("module/HomePage.js"));
const GrandFinal = lazy(() => import("module/GrandFinal.js"));
const ProfilPage = lazy(() => import("module/ProfilPage.js"));
const WizardPage = lazy(() => import("module/WizardPage.js"));
const FormulirPdf = lazy(() => import("module/profil/FormulirPdf2.js"));
const LandingPage = lazy(() => import("module/main/LandingPage.js"));
const Persyaratan = lazy(() => import("module/main/Persyaratan.js"));
// const LoginPage = lazy(()=> import("Pages/Login"));
const DaftarUlang = lazy(() => import("module/profil/DaftarUlang"));

export default function App() {
  return (
    <Suspense fallback={<div>{""}</div>}>
      <Router>
        <Switch>
          <Route path="/components/:type/:subtype/:name">
            <ComponentRenderer />
          </Route>
          <Route path="/components/:type/:name">
            <ComponentRenderer />
          </Route>
          <Route exact path="/panitia">
            <LoginPage />
          </Route>
          <Route path="/register">
            <WizardPage />
          </Route>
          <Route exact path="/home">
            <HomePage />
          </Route>
          <Route exact path="/grandfinal">
            <GrandFinal />
          </Route>
          <Route exact path="/profil">
            <ProfilPage />
          </Route>
          <Route exact path="/daftarulang">
            <DaftarUlang />
          </Route>
          <Route exact path="/formulirpdf">
            <FormulirPdf />
          </Route>
          <Route exact path="/wizard">
            <WizardPage />
          </Route>
          <Route exact path="/persyaratan">
            <Persyaratan />
          </Route>
          <Route path="/">
            <AnimationRevealPage>
              <LandingPage />
            </AnimationRevealPage>
          </Route>
        </Switch>
      </Router>
    </Suspense>
  );
}
