import React from "react";
import tw from "twin.macro";
import axios from "axios";

import { GoogleLogin } from "react-google-login";
// refresh token
import { refreshTokenSetup } from "../../utils/refreshToken";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { BASE_URL, SERVER_URL } from "utils/urlConfig";
// import { Container } from "./Layouts";
import { SectionHeading } from "components/misc/Headings.js";
// const TwoColumn = tw.div`flex flex-col md:flex-row justify-center max-w-screen-xl mx-auto py-20 md:py-24`;
const Heading = tw(SectionHeading)`text-left text-lg sm:text-2xl lg:text-3xl text-center md:text-left leading-tight`;
const clientId = "898159490455-r7gj1vosahbncn0kt7vfcn5uo8853rob.apps.googleusercontent.com";
const Container = tw.div`max-w-screen-xl mx-auto py-2 px-px sm:px-0 ml-auto  bg-opacity-50 rounded `;
// const Container = tw.div`max-w-screen-xl mx-auto py-2 px-6 sm:px-6 ml-auto  bg-opacity-50 rounded`;
const TwoColumn = tw.div`grid grid-cols-1 gap-4 sm:grid-cols-2`;
const MySwal = withReactContent(Swal);
// const PrimaryAction = tw.button`flex justify-center px-8 py-3 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 bg-gray-100 text-primary-500 font-bold rounded shadow transition duration-300 hocus:bg-primary-500 hocus:text-gray-100 focus:shadow-outline`;
const PrimaryAction = tw.button`font-medium text-lg rounded-md py-4 px-8 bg-primary-500 text-white hover:bg-primary-600 focus:outline-none focus:shadow-outline active:bg-primary-700 transition duration-150`;
// const PrimaryAction = tw.button`flex flex-row justify-between items-center h-full py-2 px-4 text-sm sm:text-base sm:px-4 sm:py-2 bg-gray-100 text-primary-500 font-bold rounded shadow transition duration-300 hocus:bg-primary-500 hocus:text-gray-100 focus:shadow-outline`;
// text-white bg-primary-500 hover:bg-primary-700 text-white font-bold py-2 px-4 rounded`;

function GoogleLoginButton() {
  let history = useHistory();
  axios.defaults.withCredentials = true;
  const onSuccess = (res) => {
    var googleProfile = res.profileObj;
    MySwal.fire({
      title: <p className="font-small">Menyambungkan akun</p>,
      allowOutsideClick: false,
      didOpen: () => {
        MySwal.showLoading();
        var email = googleProfile.email;
        localStorage.setItem("email", email);
        MySwal.fire({
          icon: "success",
          title: (
            <p className="font-small">
              Berhasil login dengan akun <br />
              {email}.<br />
              Mengecek akun
              <br /> harap tunggu
            </p>
          ),
          allowOutsideClick: false,
          didOpen: () => {
            MySwal.showLoading();
            axios
              .get(SERVER_URL + "/api/signupcheck", {
                params: {
                  email: email,
                },
              })
              .then((response) => {
                if (response.status === 200) {
                  MySwal.close();
                  let peserta = response.data[0];
                  let year = new Date().getFullYear();
                  if (peserta.tahun_daftar < year) {
                    MySwal.fire({
                      icon: "info",
                      title: (
                        <p className="font-small">
                          Akun email {email} <br />
                          sudah terdaftar pada pegelaran tahun {peserta.tahun_daftar}
                          <br />
                          Klik OK untuk melakukan registrasi baru
                        </p>
                      ),
                    }).then(() => {
                      history.push({
                        pathname: "/register",
                        state: peserta,
                      });
                    });
                  } else {
                    history.push("/home");
                  }
                } else {
                  MySwal.fire({
                    icon: "info",
                    title: (
                      <p className="font-small">
                        Akun email {email} <br />
                        belum melakukan registrasi
                        <br />
                        Klik OK untuk melakukan registrasi
                      </p>
                    ),
                  }).then(() => {
                    history.push({
                      pathname: "/register",
                      state: { email: email },
                    });
                  });
                }
              })
              .catch(() => {
                MySwal.fire({
                  icon: "error",
                  title: <p>Koneksi dengan server terputus</p>,
                });
              });
          },
        });
      },
    });

    refreshTokenSetup(res);
  };

  const onFailure = (res) => {
    MySwal.fire({
      icon: "error",
      title: (
        <p>
          Terdapat masalah pada autentikasi akun Google, silakan hubungi panitia
        </p>
      ),
    });
  };

  return (
    // <center>
    //   <div
    //   style={{ marginTop: "100px", marginBottom: "100px" }}>
    //     <GoogleLogin
    //       clientId={clientId}
    //       render={(renderProps) => (
    //         <PrimaryAction onClick={renderProps.onClick} disabled={renderProps.disabled}>
    //           Login dengan akun Google
    //         </PrimaryAction>
    //       )}
    //       buttonText="Login dengan akun Google"
    //       onSuccess={onSuccess}
    //       onFailure={onFailure}
    //       cookiePolicy={"single_host_origin"}
    //       style={{ marginTop: "100px" }}
    //       isSignedIn={true}
    //     />
    //   </div>
    // </center>
    <Container>

        <GoogleLogin
          clientId={clientId}
          render={(renderProps) => (
            <PrimaryAction onClick={renderProps.onClick} disabled={renderProps.disabled}>
              {/* <svg class="w-1 h-1 mr-1 -ml-1" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="google" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 8"><path fill="currentColor" d="M 7.773438 3.277344 C 7.820312 3.546875 7.84375 3.820312 7.84375 4.09375 C 7.84375 5.308594 7.40625 6.339844 6.648438 7.035156 L 6.652344 7.035156 C 5.988281 7.644531 5.078125 8 4 8 C 1.789062 8 0 6.210938 0 4 C 0 1.789062 1.789062 0 4 0 C 4.992188 -0.0117188 5.953125 0.363281 6.675781 1.039062 L 5.535156 2.183594 C 5.121094 1.789062 4.570312 1.574219 4 1.582031 C 2.957031 1.582031 2.070312 2.285156 1.753906 3.234375 C 1.585938 3.730469 1.585938 4.269531 1.753906 4.765625 C 2.074219 5.714844 2.957031 6.417969 4 6.417969 C 4.539062 6.417969 5.003906 6.277344 5.363281 6.035156 L 5.359375 6.035156 C 5.78125 5.757812 6.070312 5.316406 6.160156 4.820312 L 4 4.820312 L 4 3.28125 L 7.773438 3.28125 Z M 7.773438 3.277344 "></path></svg> */}
              Pendaftaran Peserta
            </PrimaryAction>
          )}
          buttonText="Login dengan akun Google"
          onSuccess={onSuccess}
          onFailure={onFailure}
          cookiePolicy={"single_host_origin"}
          isSignedIn={true}
        />

    </Container>
  );
}

export default GoogleLoginButton;
