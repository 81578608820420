import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import Header, { NavLink, NavLinks, PrimaryLink, LogoLink, NavToggle, DesktopNavLinks } from "../headers/light.js"; //eslint-disable-line
import ResponsiveVideoEmbed from "../../helpers/ResponsiveVideoEmbed.js";
import GoogleLoginButton from "components/misc/GoogleLoginButton.js";
import CustomersLogoStripImage from "../../images/denokkenangstrip-min.png";
import axios from 'axios';
import Blog from "module/dashboard/Blog.js";
import ComponenDownloadApp from "module/home/DownloadApp.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import About from "components/features/TwoColSingleFeatureWithStats2.js";

const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;
const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover`}
  background-image: url('/image/denokkenangbg-min.jpg');
`;

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-primary-500 opacity-75`;

const HeroContainer = tw.div`z-20 relative px-4 sm:px-8 max-w-screen-xl mx-auto`;
const TwoColumn = tw.div`mt-10 pt-24 pb-32 px-4 flex justify-between items-center flex-col lg:flex-row`;
const LeftColumn = tw.div`flex flex-col items-center lg:block`;
const RightColumn = tw.div`w-full sm:w-5/6 lg:w-1/2 mt-16 lg:mt-0 lg:pl-8`;

const Heading = styled.h1`
  ${tw`text-3xl text-center lg:text-left sm:text-4xl lg:text-5xl xl:text-6xl font-black text-gray-100 leading-none`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

const SlantedBackground = styled.span`
  ${tw`relative text-primary-500 px-4 -mx-4 py-2`}
  &::before {
    content: "";
    ${tw`absolute inset-0 bg-gray-100 transform -skew-x-12 -z-10`}
  }
`;

const Notification = tw.span`inline-block my-4 pl-3 py-1 text-gray-100 border-l-4 border-blue-500 font-medium text-sm`;
const HighlightedText = tw.span`text-primary-500`
const HighlightedTextInverse = tw.span`bg-gray-100 text-primary-500 px-4 transform -skew-x-12 inline-block`;
const PrimaryAction = tw.button`px-8 py-3 mt-10 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 bg-gray-100 text-primary-500 font-bold rounded shadow transition duration-300 hocus:bg-primary-500 hocus:text-gray-100 focus:shadow-outline`; // eslint-disable-line

const StyledResponsiveVideoEmbed = styled(ResponsiveVideoEmbed)`
  padding-bottom: 56.25% !important;
  padding-top: 0px !important;
  ${tw`rounded`}
  iframe {
    ${tw`rounded bg-black shadow-xl`}
  }
`;

const CustomersLogoStrip = styled.div`
  ${tw`mt-3 mr-10 mb-10 absolute lg:inset-y-0 lg:right-0 w-96`}
  img {
    ${tw`mt-4 w-full`}
  }
`;

export default () => {
  const navLinks = [
    <NavLinks key={1}>
      {/* <NavLink tw="lg:hidden" href="#">
        Timeline
      </NavLink> */}
    </NavLinks>
  ];

  const videoUrl = "https://youtu.be/dIaXqAEt-pc"
  axios.defaults.withCredentials = true
  return (
    <div>
      <Container
        style={{}}>
        <OpacityOverlay />
        <HeroContainer>
          <StyledHeader links={navLinks} />
          <CustomersLogoStrip>
            <img src={CustomersLogoStripImage} alt="Our Customers" />
          </CustomersLogoStrip>
          <TwoColumn>
            <LeftColumn>
              <Notification>Portal Resmi Pendaftaran</Notification>
              <Heading>
                <span>DENOK & KENANG</span>
                <br />
                <SlantedBackground>2023</SlantedBackground>
              </Heading>
              <GoogleLoginButton />
            </LeftColumn>
            <RightColumn>
              <StyledResponsiveVideoEmbed
                url={videoUrl}
                background="transparent"
              />
            </RightColumn>
          </TwoColumn>
        </HeroContainer>
      </Container>
      <About/>
      <Blog
        heading={<>We love <HighlightedText>Writing</HighlightedText></>}
      />
      <ComponenDownloadApp
        text={<>Temukan Wisata Menarik di <HighlightedTextInverse>Lunpia.</HighlightedTextInverse></>}
      />
      <Footer />
    </div>

  );
};
