import React, { useEffect, useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container as ContainerBase } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import illustration from "images/denokkenangpotrait.JPG";
import denokimg from "images/denok-potrait.png";
import kenangimg from "images/kenang-potrait.png";
import logo from "images/logo.svg";
import googleIconImageSrc from "images/google-icon.png";
import twitterIconImageSrc from "images/twitter-icon.png";
import { ReactComponent as SignUpIcon } from "feather-icons/dist/icons/user-plus.svg";
import { useLocation } from 'react-router-dom';
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import axios from "axios";
import ImageUploader from "react-images-upload";
import { useForm } from "react-hook-form";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { BASE_URL, SERVER_URL } from "utils/urlConfig"
import "react-datepicker/dist/react-datepicker.css";
require('react-datepicker/dist/react-datepicker.css')


const MySwal = withReactContent(Swal)
const Container = tw(ContainerBase)`min-h-screen bg-primary-900 text-white font-medium flex justify-center -m-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-2/3 xl:w-2/3 p-6 sm:p-12`;
const LogoLink = tw.a``;
const LogoImage = tw.img`h-12 mx-auto`;
const MainContent = tw.div`mt-12 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;
const FormContainer = tw.div`w-full flex-1 mt-8`;
const ErrorText = tw.span`flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1`

const DividerTextContainer = tw.div`my-12 border-b text-center relative`;
const DividerText = tw.div`leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium bg-white transform -translate-y-1/2 absolute inset-x-0 top-1/2 bg-transparent`;

const Form = tw.form`mx-auto max-w-md`;
const InputForm = ({ type, register, name, placeholder, required, onChange }) => (
  <>
    <input type={type} name={name} placeholder={placeholder} tw="w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-2 first:mt-0"
      onChange={onChange} ref={register({ required })}
    />
  </>
);

const TextareaForm = ({ rows, register, name, placeholder, required, onChange }) => (
  <>
    <input rows={rows} name={name} placeholder={placeholder} tw="w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0"
      onChange={onChange} ref={register({ required })}
    />
  </>
);

const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-2 first:mt-0`
const InputContainer = tw.div`mx-auto mt-4 first:mt-0`
const InputLabel = tw.label`text-sm text-gray-400`
const Select = tw.select`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-2 first:mt-0`;
const TextArea = tw.textarea`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`
const RadioList = tw.div`relative py-4`;
const RadioLabel = tw.label`ml-1 block text-sm font-medium text-gray-700`;
const RadioItem = tw.div`flex items-center mb-2 mr-5`;
const RadioContainer = tw.div`flex flex-wrap content-center justify-evenly mt-2 space-x-2`;
const RadioInput = tw.input`focus:border-indigo-400 h-4 w-4 text-indigo-600 border-gray-300`;
const DatePickerInput = tw.div`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;


const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;
const IllustrationContainer = tw.div`sm:rounded-r-lg flex-1 bg-purple-100 text-center hidden lg:flex justify-center`;
const IllustrationImage = styled.div`
  ${props => `background-image: url("${props.imageSrc}");`}
  ${tw`w-full max-w-lg bg-cover bg-bottom bg-no-repeat`}
`;


export default ({
  logoLinkUrl = "#",
  illustrationImageSrc = illustration,
  headingText = "Registrasi Peserta",
  jenkelArray = [
    {
      value: 0,
      text: "Denok"
    },
    {
      value: 1,
      text: "Kenang"
    }],
  identitasArray = [
    {
      value: 0,
      text: "KTP"
    },
    {
      value: 1,
      text: "Kartu Pelajar/Anggota"
    },
    {
      value: 2,
      text: "SIM"
    }],

  pekerjaanArray = [
    {
      value: 0,
      text: "Pelajar/Mahasiswa"
    },
    {
      value: 1,
      text: "PNS"
    },
    {
      value: 2,
      text: "Swasta"
    },
    {
      value: 3,
      text: "TNI/POLRI"
    },
    {
      value: 4,
      text: "Lainnya"
    }],

  daerahAsalArray = [
    {
      value: 0,
      text: "Semarang"
    },
    {
      value: 1,
      text: "Luar Semarang"
    }],
  submitButtonText = "Daftar",
  SubmitButtonIcon = SignUpIcon,
  tosUrl = "#",
  privacyPolicyUrl = "#",
  signInUrl = "#"
}) => {
  const { handleSubmit, register, errors } = useForm();
  const onSubmit = values => {};

  var minYearDate = new Date()
  minYearDate.setFullYear(minYearDate.getFullYear() - 16);
  var maxYearDate = new Date()
  maxYearDate.setFullYear(maxYearDate.getFullYear() - 25);
  const [minYear, setMinYear] = useState(minYearDate);
  const [maxYear, setMaxYear] = useState(maxYearDate);

  //set default email from location history
  let history = useHistory();
  const location = useLocation();
  var locationemail
  if (location.state !== undefined) {
    locationemail = location.state.email
  }

  const [defaultemail, setDefaultEmail] = useState(locationemail ? location : "");
  const [tgl_lahir, setTglLahir] = useState(maxYear);
  const [nama_peserta, setNamaPeserta] = useState("");
  const [email, setEmail] = useState(defaultemail);
  const [jenkel, setJenkel] = useState(0);
  const [alamat, setAlamat] = useState("");
  const [identitas, setIdentitas] = useState(0);
  const [no_identitas, setNoIdentitas] = useState("");
  const [tmpt_lahir, setTmptLahir] = useState("");
  const [tinggi_badan, setTinggiBadan] = useState("");
  const [berat_badan, setBeratBadan] = useState("");
  const [no_phone, setNoPhone] = useState("");
  const [medsos, setMedsos] = useState("");
  const [pekerjaan, setPekerjaan] = useState("");
  const [instansi, setInstansi] = useState("");
  const [bahasa, setBahasa] = useState("");
  const [is_semarang, setIsSemarang] = useState(0);
  const [alasan, setAlasan] = useState("");

  const [foto_closeup, setFotoCloseUp] = useState();
  const onDropFotoCloseUp = (picture, pictureUrl) => {
    if (picture && pictureUrl > 0) {
      const allowedMimeTypes = ["image/jpeg", "image/png", "image/jpg"];
      const fileType = picture[0].type;
      if (allowedMimeTypes.includes(fileType)) {
        setFotoCloseUp(picture);
      } else {
        MySwal.fire({
          icon: 'error',
          title: <p>Format file tidak didukung</p>,
        })
      }
    } else {
      MySwal.fire({
        icon: 'error',
        title: <p>Format file tidak didukung</p>,
      })
    }
  };
  const [foto_fullbody, setFotoFullbody] = useState();
  const onDropFotoFullbody = (picture, pictureUrl) => {
    if (picture && pictureUrl > 0) {
      const allowedMimeTypes = ["image/jpeg", "image/png", "image/jpg"];
      const fileType = picture[0].type;
      if (allowedMimeTypes.includes(fileType)) {
        setFotoFullbody(picture);
      } else {
        MySwal.fire({
          icon: 'error',
          title: <p>Format file tidak didukung</p>,
        })
      }
    } else {
      MySwal.fire({
        icon: 'error',
        title: <p>Format file tidak didukung</p>,
      })
    }
  };

  const ExampleCustomInput = ({ onClick, value }) => (
    <span className="w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-2 first:mt-0"
      onClick={onClick}>{value}</span>
  );

  const registerForm = () => {
    // e.preventDefault();
    if (foto_closeup === undefined || foto_fullbody === undefined) {
      MySwal.fire({
        icon: 'error',
        title: <p>Belum mengupload foto</p>,
      })
    } else {
      MySwal.fire({
        title: <p className="font-small">Menyimpan Data</p>,
        allowOutsideClick: false,
        didOpen: () => {
          MySwal.showLoading()
          const data = new FormData();
          data.append('file', foto_closeup[0])
          data.append('file', foto_fullbody[0])
          axios.post(SERVER_URL + "/api/uploadfile", data).then((res) => {
            axios.post(SERVER_URL + "/api/register", {
              nama_peserta: nama_peserta,
              email: email,
              jenkel: jenkel,
              alamat: alamat,
              identitas: identitas,
              no_identitas: no_identitas,
              tmpt_lahir: tmpt_lahir,
              tinggi_badan: tinggi_badan,
              berat_badan: berat_badan,
              no_phone: no_phone,
              medsos: medsos,
              pekerjaan: pekerjaan,
              instansi: instansi,
              bahasa: bahasa,
              is_semarang: is_semarang,
              alasan: alasan,
              tgl_lahir: tgl_lahir,
              foto_closeup: "/" + res.data[0].path,
              foto_fullbody: "/" + res.data[1].path
            }).then((response) => {
              if (response.status === 200) {
                MySwal.fire({
                  icon: 'success',
                  title: <p>Berhasil Registrasi</p>,
                }).then((result) => {
                  history.push('/home')
                })
              } else {
                MySwal.fire({
                  icon: 'error',
                  title: <p>Registrasi gagal<br />Silakan coba kembali</p>,
                })
              }
            }).catch(() => {
              MySwal.fire({
                icon: 'error',
                title: <p>Ada kendala pada server<br />Silakan coba kembali</p>,
              }).then(() => {
                history.push({
                  pathname: '/register',
                  state: {  // location state
                    email: email,
                  }
                })
              })
            })
          }).catch(() => {
            MySwal.fire({
              icon: 'error',
              title: <p>Data atau gambar tidak lengkap<br />Silakan coba kembali</p>,
            })
          })
        }
      })
    }


  }


  axios.defaults.withCredentials = true

  useEffect(() => {
    axios.get(SERVER_URL + '/api/emailsession').then((response) => {
      if (response.data.emailSession == true) {
        setDefaultEmail(response.data.email)
        setEmail(response.data.email)
      } else {
        MySwal.fire({
          icon: 'error',
          title: <p>Anda belum login<br />dengan akun google</p>,
        }).then(() => {
          history.push({
            pathname: '/'
          })
        })
      }
    }).catch((error) => {
      MySwal.fire({
        icon: 'error',
        title: <p>Ada kendala pada server<br />Silakan coba kembali</p>,
      }).then(() => {
        history.push({
          pathname: '/'
        })
      })
    })
  }, [])
  // for query strings;
  return (
    <AnimationRevealPage>
      <Container>
        <Content>
          <MainContainer>
            <LogoLink href={logoLinkUrl}>
              <LogoImage src={logo} />
            </LogoLink>
            <MainContent>
              <Heading>{headingText}</Heading>
              <FormContainer>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <InputContainer>
                    <InputLabel>Nama Lengkap</InputLabel>
                    <InputForm type="text" register={register} name="nama_peserta" placeholder="Nama Lengkap" required
                      onChange={(e) => {
                        setNamaPeserta(e.target.value)
                      }} />
                    {errors.nama_peserta && <ErrorText>"Nama Peserta belum diisi"</ErrorText>}
                  </InputContainer>
                  <InputContainer>
                    <InputLabel>Email</InputLabel>
                    <Input type="email" placeholder="Email" value={defaultemail} name="email" readOnly />
                  </InputContainer>
                  <InputContainer>
                    <InputLabel>Jenis Peserta</InputLabel>
                    <RadioList>
                      <RadioContainer>
                        {jenkelArray.map((obj, index) => (
                          <RadioItem key={index}>
                            <RadioInput id={obj.text} name="jenkel" type="radio"
                              checked={jenkel === obj.value} value={obj.value} onClick={() => setJenkel(obj.value)}
                            />
                            <RadioLabel htmlFor={obj.text}>
                              {obj.text}
                            </RadioLabel>
                          </RadioItem>
                        ))}
                      </RadioContainer>
                    </RadioList>
                  </InputContainer>
                  <InputContainer>
                    <InputLabel>Tanggal Lahir (min:16; max:25 tahun ini)</InputLabel>
                    <DatePickerInput>
                      <DatePicker selected={tgl_lahir} showYearDropdown={true} minDate={maxYear} maxDate={minYear} name="tgl_lahir"
                        dateFormat="dd/MM/yyyy" onChange={date => setTglLahir(date)} customInput={<ExampleCustomInput />} />
                    </DatePickerInput>
                  </InputContainer>
                  <InputContainer>
                    <InputLabel>Alamat Domisili/Tinggal</InputLabel>
                    <TextareaForm rows="3" register={register} name="alamat" placeholder="Alamat Domisili" required
                      onChange={(e) => {
                        setAlamat(e.target.value)
                      }} />
                    {errors.alamat && <ErrorText>"Alamat domisili belum diisi"</ErrorText>}
                  </InputContainer>
                  <InputContainer>
                    <InputLabel>Jenis dan Nomor Identitas</InputLabel>
                    <RadioList>
                      <RadioContainer>
                        {identitasArray.map((obj, index) => (
                          <RadioItem key={index}>
                            <RadioInput id={obj.text} name="identitas" type="radio" required
                              checked={identitas === obj.value} value={obj.value} onClick={() => setIdentitas(obj.value)}
                            />
                            <RadioLabel htmlFor={obj.text}>
                              {obj.text}
                            </RadioLabel>
                          </RadioItem>
                        ))}
                      </RadioContainer>
                    </RadioList>
                    <InputForm type="text" register={register} name="no_identitas" placeholder="Nomor Identitas" required
                      onChange={(e) => {
                        setNoIdentitas(e.target.value)
                      }} />
                    {errors.no_identitas && <ErrorText>"Nomor identitas belum diisi"</ErrorText>}
                  </InputContainer>
                  <InputContainer>
                    <InputLabel>Tempat Lahir</InputLabel>
                    <InputForm type="text" register={register} name="tmpt_lahir" placeholder="Tempat Lahir" required
                      onChange={(e) => {
                        setTmptLahir(e.target.value)
                      }} />
                    {errors.tmpt_lahir && <ErrorText>"Tempat Lahir belum diisi"</ErrorText>}
                  </InputContainer>
                  <InputContainer>
                    <InputLabel>Tinggi dan Berat Badan</InputLabel>
                    <InputForm type="number" register={register} name="tinggi_badan" placeholder="Tinggi Badan" required
                      onChange={(e) => {
                        setTinggiBadan(e.target.value)
                      }} />
                    {errors.tinggi_badan?.type === "required" && <ErrorText>"Tinggi Badan belum diisi"</ErrorText>}
                    <InputForm type="number" register={register} name="berat_badan" placeholder="Berat Badan" required
                      onChange={(e) => {
                        setBeratBadan(e.target.value)
                      }} />
                    {errors.berat_badan?.type === "required" && <ErrorText>"Berat Badan belum diisi"</ErrorText>}
                  </InputContainer>
                  <InputContainer>
                    <InputLabel>Informasi Kontak</InputLabel>
                    <InputForm type="text" register={register} name="no_phone" placeholder="No. Handphone" required
                      onChange={(e) => {
                        setNoPhone(e.target.value)
                      }} />
                    {errors.no_phone && <ErrorText>"Nomor Handphone belum diisi"</ErrorText>}
                    <InputForm type="text" register={register} name="medsos" placeholder="Nama akun Instagram" required
                      onChange={(e) => {
                        setMedsos(e.target.value)
                      }} />
                    {errors.medsos && <ErrorText>"Nama akun Instagram belum diisi"</ErrorText>}
                  </InputContainer>
                  <InputContainer>
                    <InputLabel>Pekerjaan/Status</InputLabel>
                    <Select name="pekerjaan" onChange={(e) => setPekerjaan(e.target.value)} value={pekerjaan} required>
                      <option value="">-Pilih pekerjaan/status-</option>
                      {pekerjaanArray.map((obj, index) => (
                        <option value={obj.value}>{obj.text}</option>
                      ))}
                    </Select>
                    {pekerjaan == "" ? <ErrorText>"Jenis pekerjaan/status belum dipilih"</ErrorText> : null}
                  </InputContainer>
                  <InputContainer>
                    <InputLabel>Nama Instansi/Sekolah</InputLabel>
                    <InputForm type="text" register={register} name="instansi" placeholder="Nama Instansi/Sekolah" required
                      onChange={(e) => {
                        setInstansi(e.target.value)
                      }} />
                    {errors.instansi && <ErrorText>"Nama Instansi/sekolah belum diisi"</ErrorText>}
                  </InputContainer>
                  <InputContainer>
                    <InputLabel>Bahasa yang dikuasai</InputLabel>
                    <TextareaForm rows="2" register={register} name="bahasa" placeholder="Bahasa yang dikuasai" required
                      onChange={(e) => {
                        setBahasa(e.target.value)
                      }} />
                    {errors.bahasa && <ErrorText>"Bahasa yang dikuasai belum diisi"</ErrorText>}
                  </InputContainer>
                  <InputContainer>
                    <InputLabel>Asal Peserta (Sesuai KTP)</InputLabel>
                    <RadioList>
                      <RadioContainer>
                        {daerahAsalArray.map((obj, index) => (
                          <RadioItem key={index}>
                            <RadioInput id={obj.text} name="is_semarang" type="radio"
                              checked={is_semarang === obj.value} value={obj.value} onClick={() => setIsSemarang(obj.value)}
                            />
                            <RadioLabel htmlFor={obj.text}>
                              {obj.text}
                            </RadioLabel>
                          </RadioItem>
                        ))}
                      </RadioContainer>
                    </RadioList>
                    {errors.is_semarang && <ErrorText>"Asal peserta belum dipilih"</ErrorText>}
                  </InputContainer>
                  <InputContainer>
                    <InputLabel>Foto Close-Up</InputLabel>
                    <ImageUploader
                      // name= "foto_closeup"
                      buttonText="Pilih Gambar"
                      className="w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-2 first:mt-0"
                      singleImage={true}
                      withIcon={true}
                      onChange={onDropFotoCloseUp}
                      imgExtension={[".jpg", ".gif", ".png", ".gif"]}
                      maxFileSize={5242880}
                      fileSizeError="Ukuran file terlalu besar"
                      withPreview={true}
                    />
                  </InputContainer>
                  <InputContainer>
                    <InputLabel>Foto Full-Body</InputLabel>
                    <ImageUploader
                      // name= "foto_fullbody"
                      buttonText="Pilih Gambar"
                      className={"bg-gray-100 border border-gray-200 focus:border-gray-400 focus:bg-white mt-2"}
                      singleImage={true}
                      withIcon={true}
                      onChange={onDropFotoFullbody}
                      imgExtension={[".jpg", ".gif", ".png", ".gif"]}
                      maxFileSize={5242880}
                      fileSizeError="Ukuran file terlalu besar"
                      withPreview={true}
                    />
                  </InputContainer>
                  <InputContainer>
                    <InputLabel>Alasan Mendaftar</InputLabel>
                    <TextareaForm rows="2" register={register} name="alasan" placeholder="Alasan mengikuti Denok&Kenang" required
                      onChange={(e) => {
                        setAlasan(e.target.value)
                      }} />
                    {errors.alasan && <ErrorText>"Alasan mendaftar belum diisi"</ErrorText>}
                  </InputContainer>
                  <SubmitButton type="submit" onClick={handleSubmit(registerForm)}>
                    <SubmitButtonIcon className="icon" />
                    <span className="text">{submitButtonText}</span>
                  </SubmitButton>
                </Form>
              </FormContainer>
            </MainContent>
          </MainContainer>
          {jenkel == 1 &&
            <IllustrationContainer>
              <IllustrationImage imageSrc={denokimg} />
            </IllustrationContainer>}
          {jenkel == 0 &&
            <IllustrationContainer>
              <IllustrationImage imageSrc={kenangimg} />
            </IllustrationContainer>}
        </Content>
      </Container>
    </AnimationRevealPage>
  )
};
