import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithVideo.js";
import MainFeature from "components/features/TwoColWithButton.js";
import DownloadApp from "components/cta/DownloadApp.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import Wawancara from  "components/features/DashedBorderSixFeatures.js";
import Pengunguman from  "components/forms/TwoColContactUsWithIllustration.js";

export default () => {
  const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
  const HighlightedTextInverse = tw.span`bg-gray-100 text-primary-500 px-4 transform -skew-x-12 inline-block`;
  const Description = tw.span`inline-block mt-8`;
  const imageCss = tw`rounded-4xl`;
  return (
    <AnimationRevealPage>
      <Hero
        heading={<>Pendaftaran & <HighlightedText>Verifikasi Data</HighlightedText></>}
        description="Lengkapi Data Diri anda Sampai tanggal 20 Maret 2023"
        imageSrc="https://bidikutama.com/wp-content/uploads/2020/09/ilustrasi-formulir-presensi-750x375.jpg"
        imageCss={imageCss}
        imageDecoratorBlob={true}
        primaryButtonText="Lengkapi Sekarang"
      />
      <MainFeature
        heading={
          <>
            TES TERTULIS
            <wbr /> <HighlightedText>Online</HighlightedText>
          </>
        }
        description={
          <Description>
            Tes Tertulis Dilaksakan pada tanggal 22 maret 2023 jam 00:00
          </Description>
        }
        buttonRounded={false}
        textOnLeft={false}
        primaryButtonText="Mulai Tes"
        imageSrc={
          "https://www.minews.id/wp-content/uploads/2019/12/JBw8O7oflG-feature.jpeg"
        }
        imageCss={imageCss}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
      />
      {/* TabGrid Component also accepts a tabs prop to customize the tabs and its content directly. Please open the TabGrid component file to see the structure of the tabs props.*/}
      <Wawancara/>
      
      <Pengunguman
        heading={
          <>
            Pengumuman <HighlightedText>Online.</HighlightedText>
          </>
        }
      />
      <DownloadApp
        text={<>Temukan Wisata Menarik di <HighlightedTextInverse>Lunpi App.</HighlightedTextInverse></>}
      />
      <Footer />
    </AnimationRevealPage>
  );
}
